import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

import SignIn from "./login/SignIn";
import SignUp from "./login/SignUp";
import ResetPassword from "./login/ResetPassword";
import EmailSent from "./login/EmailSent";
import ConfirmEmail from "./login/ConfirmEmail";
import Dashboard from "./dashboard/Dashboard";
import Dashboard1 from "./dashboard/Dashboard1";
import DataGrid from "./sample/DataGrid";
import { Counter } from "./counter/Counter";
import Auth from "../components/auth/Auth";
import AuthPortal from "../components/auth/AuthPortal";

// import Layout from './layout/Layout';
import { DashboardLayout } from "./layout/DashboardLayout";
import { HomeLayout } from "./layout/Home/HomeLayout";
import MainContainer from "./layout/Home/MainContainer";
import CompanyDetails from "./company/CompanyDetails";
import CompanyNewReview from "./company/CompanyNewReview";
import CreateEditCompany from "./company/CreateEditCompany";
import { isLoggedIn } from "../redux/userSlice";
import Privacy from "./legal/Privacy";
import Terms from "./legal/Terms";

export default function AppRoutes() {
  const isUserLoggedIn = useAppSelector(isLoggedIn);

  return (
    <Router>
      {/* <div> */}
      {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

      <Routes>
        {/* <Route element={<AuthPortal />}>
          <Route path="/account" element={<DashboardLayout />}>
            <Route path="/account" element={<Dashboard />} />
            <Route path="/account/dashboard" element={<Dashboard1 />} />
            <Route path="/account/datagrid" element={<DataGrid />} />
            <Route path="/account/counter" element={<Counter />} />
          </Route>
        </Route> */}
        {/* <Route  path="/layout" element={<Layout />} /> */}
        {/* <Route path="/login" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/confirm">
          <Route path=":tokenId" element={<ConfirmEmail />} />
        </Route> */}

        <Route element={<Auth />}>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<MainContainer />} />
            <Route
              path="/it-services/it-consulting"
              element={<Navigate to="/" />}
            />
            <Route path="/company/:id" element={<CompanyDetails />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />

            <Route
              path="/review/add"
              element={
                isUserLoggedIn ? (
                  <CompanyNewReview />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/company/create"
              element={
                isUserLoggedIn ? (
                  <CreateEditCompany />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            {/* <Route path="company/create" element={<CreateEditCompany />} /> */}
          </Route>
        </Route>
        {/* <Route path="/emailsent" element={<EmailSent />} /> */}
        {/* <Route path="/about">
            <About />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/">
            <Home />
          </Route> */}
      </Routes>
      {/* </div> */}
    </Router>
  );
}
